<template>
  <div class="tw-relative tw-w-full">
    <Navbar
      class="tw-z-10 tw-absolute tw-top-0 tw-left-0 tw-w-full"
      :darkLogo="true"
      :darkMenu="true"
    />
    <div class="space"></div>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from './common/Navbar.vue';

export default {
  components: { Navbar },
  computed: {
    fullWidth: function() {
      return this.$route.meta.fullWidth;
    },
    darkLogo: function() {
      return this.$route.meta.darkLogo;
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';
.logo {
  font-family: Quicksand;
  font-size: 44px;
  color: black;
  @include respond-to(xs) {
    font-size: 35px;
  }
}

.space {
  height: 172px;
  width: 100%
}
</style>
